//コピーライト
var TodayData = new Date();
var thisYear = TodayData.getFullYear();
window.onload = function() {
	document.getElementById('js_copyYear').innerHTML = '©' + ' ' + thisYear + ' Copyright © Confeed All Right Reserved';
};

// ヘッダー動作（下にスクロールで背景色変更）
$(function(){
  var imgHeight = $('.mainVisual').outerHeight(); //画像の高さを取得。これがイベント発火位置になる。
  var header = $('#header'); //ヘッダーコンテンツ

  $(window).on('load scroll', function(){
     if ($(window).scrollTop() < imgHeight) {
       //メインビジュアル内にいるので、クラスを外す。
       header.removeClass('Header__bgColor');
     }else {
       //メインビジュアルより下までスクロールしたので、クラスを付けて色を変える
       header.addClass('Header__bgColor');
     }
  });
});

// ヘッダー動作
var startPos = 0;
$(window).scroll(function(){
  var currentPos = $(this).scrollTop();
  if (currentPos > startPos) {
    if($(window).scrollTop() >= 100) {
      $("#header").css("top", "-" + 200 + "px");
    }
  } else {
    $("#header").css("top", 0 + "px");
  }
  startPos = currentPos;
});

// ハンバーガーメニュー
$(function(){
		$("#js_gnavMenu-openBtn").on("click", function() {
				var scrollTop = $(window).scrollTop();
				$(this).next().fadeToggle();
				$(this).toggleClass("js_gnavMenu-open");
				$('#wrapper').css({'position':'fixed','top':-scrollTop});
				$('nav').toggleClass('Header__bgColor');
		});
});
$(function(){
		var w = $(window).width();
		var scrollTop = $(window).scrollTop();
		if (w < 768) {
			$("#js_gnavMenu-closeBtn").on("click", function() {
					$('#wrapper').css({'position':'','top':'0'});
					$('html,body').scrollTop(scrollTop);
					$('#js_gnavMenu-openBtn').next().fadeToggle();
					$('#js_gnavMenu-openBtn').toggleClass("js_gnavMenu-open");
					$('nav').toggleClass('Header__bgColor');
			});
			$("#js_gnavMenu-closeSub").on("click", function() {
					$('#wrapper').css({'position':'','top':'0'});
					$('html,body').scrollTop(scrollTop);
					$('#js_gnavMenu-openBtn').next().fadeToggle();
					$('#js_gnavMenu-openBtn').toggleClass("js_gnavMenu-open");
			});

		}
});



// フッターの固定ボタン

$(document).ready(function(){

		// ページがスクロールされるまでは隠しておく
		$("#FixedFt").hide();

		// スクロールが始まったら
		$(window).on("scroll", function() {
				// 100pxスクロールしたら表示
				if ($(this).scrollTop() > 100) {
						$('#FixedFt').slideDown("fast");
				// ページの一番上では非表示
				} else {
						$('#FixedFt').slideUp("fast");
				}

				// ページの高さ
				var scrollHeight = $(document).height();

				//  表示画面の高さ ＋ ページに対するスクロール量
				var scrollPosition = $(window).height() + $(window).scrollTop();

				//  フッターの高さ
				var footHeight = $(".FtSMap").innerHeight();

				// フッターの位置（ページの高さ − 表示画面の高さ ＋ ページに対する現在のスクロール量）までスクロールしてきたとき
				if ( scrollHeight - scrollPosition  <= footHeight ) {

				// 現在の下から位置が、フッターの高さの位置にはいったら
				// ".gotop"のpositionをabsoluteに変更し、フッターの高さの位置にする

						$("#FixedFt").css({
								"position": "absolute",
								"bottom": footHeight,
						});
				} else {
				// それ以外の場合は元のcssスタイルを指定
						$("#FixedFt").css({
								"position": "fixed",
								"bottom": "0px",
						});
				}
		});
});

// スムーズスクロール
$(function(){
	 $('a[href^="#"]').click(function() {
			var speed = 300;
			var href= $(this).attr("href");
			var target = $(href == "#" || href == "" ? 'html' : href);
			var position = target.offset().top;
			$('html,body').animate({scrollTop:position}, speed, 'swing');
			return false;
	 });
});


// ホバーメニュー
$(function(){
  $('.hoverArea').hover(function() {
    var w = $(window).width();
    if (w > 768) {
      $(this).toggleClass('active');
      $(this).children('.hoverContent').stop().slideDown();
    }
  },
  function() {
    var w = $(window).width();
    if (w > 768) {
      $(this).toggleClass('active');
      $(this).children('.hoverContent').stop().slideUp();
    }
  });
});

// トグル
$(function(){
  $('.toggleBtn').click(function() {
    var w = $(window).width();
    if (w < 767) {
      $(this).toggleClass('active');
      $(this).next('.toggleContents').slideToggle();
    }
  });
});

//アコーディオン
$(function(){
  $(".accordion li a").on("click", function() {

    $(this).next().slideToggle(); 
$(this).addClass('active');
    // activeが存在する場合
    if ($(this).children(".accordion_icon").hasClass('active')) {   

      // activeを削除
      $(this).children(".accordion_icon").removeClass('active'); 
      $(this).removeClass('active');

    }
    else {
      // activeを追加
      $(this).children(".accordion_icon").addClass('active');  
         
    }     
  });
});

/*
$(document).ready(function () {
      $('#form').submit(function (event) {
        var formData = $('#form').serialize();
        $.ajax({
          url: "https://docs.google.com/forms/u/0/d/e/1FAIpQLSdkOQlqJyJcmqb8MIDZw9JgHs_bO5u8pM-XR60yPFBVUBA-Mg/formResponse",
          data: formData,
          type: "POST",
          dataType: "xml",
          statusCode: {
            0: function () {
              $(".end-message").slideDown();
              $(".submit-btn").fadeOut();
              //window.location.href = "thanks.html";
            },
            200: function () {
              $(".false-message").slideDown();
            }
          }
        });
        event.preventDefault();
      });

    });
    */
